<template>
    <div class="container">
        <h1>Roster Pal - Personal - 2025</h1><br><br>
        <form @submit.prevent="handleSubmit" class="form">
            <v-row>
                <v-col cols="12" md="4">
                    <label for="date">Select Date:</label>
                    <input type="date" v-model="date" required />
                </v-col>

                <v-col cols="12" md="4">
                    <label for="name">Enter Name:</label>
                    <input type="text" v-model="name" required />
                </v-col>

                <button type="submit" class="submit-button">Show the week</button>
            </v-row>
        </form>

        <div v-if="result" class="result">
            <h2>Shift Pattern:</h2>
            <v-chip-group class="d-flex flex-column">
                <v-chip v-for="(value, key) in about" :key="key"
                    :style="{ backgroundColor: getChipColor(key), color: 'white' }">
                    {{ value }}
                </v-chip>
            </v-chip-group>

            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Shift</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in result" :key="item.Date"
                        :style="{ backgroundColor: item.Date.startsWith('S') ? '#A7C7E7' : 'white' }">
                        <td>{{ item.Date }}
                            <v-chip v-if="item.Date.startsWith('Sun') && item.Shift === 'Night'" key=""
                                color="#9DD8A6">OT</v-chip>
                        </td>
                        <td>{{ item.Shift }}</td>
                    </tr>
                </tbody>


            </table>
        </div>
        <modal-component :isVisible="showModal" :options="options" @close="showModal = false"
            @select="handleSelect"></modal-component>
        <v-snackbar v-model="snackbar" timeout=2000 centered col>
            Invalid Data

            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import ModalComponent from './ModalComponent.vue';
export default {
    components: {
        ModalComponent
    },
    data() {
        return {
            date: new Date().toISOString().split("T")[0], // Default to today's date
            name: "",
            result: null,
            showModal: false,
            options: [],
            about: {
                Name: '',
                Capability: '',
                Roster: ''
            },
            snackbar: false
        };
    },
    methods: {
        async handleSubmit() {
            localStorage.setItem("myNameCookie", this.name); // Save to local storage
            await this.fetchData(); // Await fetchData to ensure it completes before proceeding
        },
        async fetchData() {
            const formattedDate = this.date.replace(/-/g, "/");
            const year = formattedDate.split('/')[0];
            if (year != 2025) {
                alert("RosterPal only has data of the year 2025");
                return
            }

            const url = `https://rosterpalb.induwara.me/getRoster/${formattedDate}/${this.name}`;

            try {
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // Check if the response is in the expected format
                // console.log(data);

                if (data[0].Capability) {
                    // Extract shift data from the response
                    const aboutData = data.slice(0).flat()[0]; // Assuming this returns the about object
                    const shifts = data.slice(1).flat();
                    // console.log((aboutData.length));

                    this.result = shifts; // Store the fetched data

                    // Update the about object with the fetched values
                    this.about.Capability = aboutData.Capability + " - " + aboutData.Area; // Set Capability
                    this.about.Name = aboutData.Name;             // Set Name
                    this.about.Roster = aboutData.Roster;         // Set Roster
                } else {
                    this.options = this.extractOptions(data); // Extract options based on your logic
                    this.showModal = true; // Show the modal
                    // Handle the case where the response does not match the expected format
                    console.log("Unexpected response format:", response);
                }


            } catch (error) {
                this.snackbar = true
                console.error('Error fetching data:', error);
            }
        }, extractOptions(data) {
            // Logic to extract options from the response data
            return data.map(item => item); // Replace 'someProperty' with the relevant property
        },
        handleSelect(option) {
            this.name = option
            this.handleSubmit()
            // console.log("Selected option:", option);
            // Handle the selected option here
        },
        getChipColor(key) {
            switch (key) {
                case 'Capability':
                    return 'red'; // Change to your desired color
                case 'Name':
                    return 'green'; // Change to your desired color
                default:
                    return 'orange'; // Change to your desired color
            }
        }
    },

    mounted() {
        const savedName = localStorage.getItem("myNameCookie");
        if (savedName) {
            this.name = savedName;
        }
    }
};
</script>

<style scoped>
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
}

.container {
    flex: 1;
    width: 90%;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

header {
    background-color: #6a1b9a;
    color: white;
    padding: 1rem 0;
    text-align: center;
}

header nav a {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
    font-weight: bold;
}

h1 {
    text-align: center;
    color: #6a1b9a;
}

form {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

label {
    margin-right: 0.5rem;
    font-weight: bold;
}

input[type="date"],
input[type="text"],
select,
button {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

button {
    background-color: #6a1b9a;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #5a1282;
}

.result {
    margin-top: 1.5rem;
    padding: 1.5rem;
    border: 1px solid #ddd;
    background-color: #fafafa;
    border-radius: 8px;
}


.result h2 {
    margin-top: 0;
    color: #6a1b9a;
}

.result table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

.result table,
.result th,
.result td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    text-align: left;
}

.result th {
    background-color: #f5f5f5;
}

.response-text {
    background-color: #f4f4f4;
    padding: 1rem;
    border-radius: 4px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

footer {
    text-align: center;
    padding: 1rem;
    background-color: #333;
    color: white;
}

@media (min-width: 600px) {
    .container {
        width: 80%;
    }

    form {
        justify-content: space-between;
    }
}
</style>